/** @typedef {{ floor: string, id: string, location: { x: number, y: number }, roomID: string, spaceType: string, spaceName: string, rotation: number }} Image */

import { roundTo } from "./roundTo.js";

/** @typedef {{ dimensions: { metric: { height: string|undefined, width: string|undefined }, imperial: { height: string|undefined, width: string|undefined }}}} Room */

/**
 * @param {*} transforms
 * @param {Element | undefined} simplifiedSvg
 * @return {{ images: Object.<string, Image>, rooms: Object.<string, Room>, order: Array.<string>, floors: Array.<number>, floorLabelMap: Object.<number, string> }}
 */
export const getComputedTransforms = (transforms, simplifiedSvg) => {
  /** @type {Object.<string, Image>} */
  const images = {};
  /** @type {Object.<string, Room>} */
  const rooms = {};
  /** @type {number[]} */
  let floors = [];

  /** @type {string[]} */
  let order = [];
  /** @type {Object.<string, string[]>} */
  const nonOrderedImages = {};

  /** @type {Object.<number, string>} */
  const floorLabelMap = {};

  // Support for two photomapping.json versions.
  // Original where the images are in the json root
  // and for newer one where images are inside images node
  let transformImages = transforms;

  if (transforms.images != undefined) {
    transformImages = transforms.images;
  }

  Object.keys(transformImages).forEach((key) => {
    const transform = transformImages[key];

    if (!transform.success || transform.hidden) {
      return;
    }

    if (!rooms[transform.roomID]) {
      /**
       * @param {number} m meters
       * @returns {string|undefined}
       */
      const toRoundMeters = (m) => {
        if (!m && m !== 0) {
          return;
        }
        m = roundTo(m, 1);
        let returnValue = `${m}`.replace(".", ",");

        return returnValue;
      };

      let metricHeight;
      let metricWidth;
      if (
        transform.room_dimensions?.height &&
        transform.room_dimensions?.width
      ) {
        metricHeight = `${toRoundMeters(transform.room_dimensions?.height)}m`;
        metricWidth = `${toRoundMeters(transform.room_dimensions?.width)}m`;
      }

      /**
       * @param {number} n centimeters
       * @returns {string|undefined}
       */
      const toFeetInches = (n) => {
        if (!n) {
          return undefined;
        }

        let realFeet = (n * 0.3937) / 12;
        let feet = Math.floor(realFeet);
        let inches = Math.round((realFeet - feet) * 12);
        return `${feet}'${inches}"`;
      };

      const imperialHeight = toFeetInches(
        transform.room_dimensions?.height * 100,
      );
      const imperialWidth = toFeetInches(
        transform.room_dimensions?.width * 100,
      );

      rooms[transform.roomID] = {
        dimensions: {
          metric: {
            height: metricHeight,
            width: metricWidth,
          },
          imperial: {
            height: imperialHeight,
            width: imperialWidth,
          },
        },
      };
    }

    if (transform.order || transform.order === 0) {
      order[transform.order] = key;
    } else {
      if (!nonOrderedImages[transform.floor]) {
        nonOrderedImages[transform.floor] = [];
      }
      nonOrderedImages[transform.floor].push(key);
    }

    delete transform.room_dimensions;
    delete transform.success;
    delete transform.order;

    images[key] = {
      ...transform,
      location: {
        x: transform.location[0],
        y: transform.location[1],
      },
      rotation: transform.rotation + 90,
      id: key,
    };

    if (!floors.includes(+transform.floor)) {
      floors.push(+transform.floor);
    }
  });

  Object.keys(nonOrderedImages).forEach((key) => {
    order.push(...nonOrderedImages[key]);
  });

  // Remove falsy values
  order = order.filter(Boolean);

  if (!simplifiedSvg) {
    floors.forEach((floor) => {
      floorLabelMap[floor] = `${floor + 1}`;
    });
  } else {
    // Clear the floors array, it was used for fallback
    floors = [];

    simplifiedSvg
      .querySelectorAll("#FloorNumberLabel")
      .forEach((labelContainer) => {
        const prevElHref =
          labelContainer.previousElementSibling?.getAttribute("xlink:href");
        if (!prevElHref) {
          return;
        }
        const svgFloorNumber = prevElHref.at(-1);
        if (!svgFloorNumber || Number.isNaN(svgFloorNumber)) {
          return;
        }

        const curFloor = +svgFloorNumber - 1;
        let label = labelContainer.firstChild?.firstChild?.textContent;
        label = label?.toLowerCase();
        label = String(label).charAt(0).toUpperCase() + String(label).slice(1);

        floorLabelMap[curFloor] = label || `${curFloor + 1}`;

        // Floors ordering
        floors.push(curFloor);
      });
  }

  return {
    images,
    rooms,
    order,
    floors,
    floorLabelMap,
  };
};
