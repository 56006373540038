import { appStore } from "../../store/index.js";

import "./background.css";

class Background extends HTMLElement {
  constructor() {
    super();

    this.imgEl = undefined;

    /** @type {Object.<string, {left: number, top: number}>} */
    this.blobPositions = {
      1: {
        left: 10,
        top: 90,
      },
      2: {
        left: 84,
        top: 67,
      },
      3: {
        left: 46,
        top: 48,
      },
      4: {
        left: 26,
        top: 38,
      },
      5: {
        left: 7,
        top: 84,
      },
      6: {
        left: 89,
        top: 5,
      },
    };

    /** @type {ReturnType<typeof setInterval> | undefined} */
    this.blobInterval = undefined;
  }

  connectedCallback() {
    if (this.imgEl) {
      return;
    }

    this.imgEl = document.createElement("img");
    this.appendChild(this.imgEl);

    appStore.events.subscribe("stateChange", () => this.update());

    document.addEventListener("mousemove", (e) => {
      requestAnimationFrame(() => {
        this.onMouseMove(e);
      });
    });

    for (let i = 0; i < 6; i++) {
      const blob = document.createElement("div");
      blob.classList.add("blob");
      blob.classList.add(`b-${i + 1}`);
      this.appendChild(blob);
    }

    this.blobInterval = setInterval(() => {
      requestAnimationFrame(() => {
        this.randomizeBlobPositions();
      });
    }, 1000);

    this.imgEl.addEventListener("load", () => {
      const blobs = this.querySelectorAll(".blob");
      blobs.forEach((blob) => blob.remove());

      if (this.blobInterval) {
        clearInterval(this.blobInterval);
        this.blobInterval = undefined;
      }
    });
  }

  randomizeBlobPositions() {
    Object.keys(this.blobPositions).forEach((key, i) => {
      const blob = this.blobPositions[key];

      const randomNumberTop = Math.random() * 6.5;
      const randomNumberLeft = Math.random() * 6.5;

      let isPositiveTop = Math.random() > 0.5;
      let isPositiveLeft = Math.random() > 0.5;

      if (blob.top < 1) isPositiveTop = true;
      if (blob.top > 99) isPositiveTop = false;

      if (blob.left < 1) isPositiveLeft = true;
      if (blob.left > 99) isPositiveLeft = false;

      if (isPositiveTop) {
        blob.top = blob.top + randomNumberTop;
      } else {
        blob.top = blob.top - randomNumberTop;
      }

      if (isPositiveLeft) {
        blob.left = blob.left + randomNumberLeft;
      } else {
        blob.left = blob.left - randomNumberLeft;
      }

      this.style.setProperty(`--b-${i + 1}-top`, `${blob.top}%`);
      this.style.setProperty(`--b-${i + 1}-left`, `${blob.left}%`);
    });
  }

  /**
   * @param {MouseEvent} e
   */
  onMouseMove(e) {
    const halfWidth = window.innerWidth / 2;
    const halfHeight = window.innerHeight / 2;

    const mouseY = e.clientY;
    const mouseX = e.clientX;

    const strength = 120;

    this.style.setProperty(
      "--x-offset",
      `${(halfWidth - mouseX) / strength}px`,
    );
    this.style.setProperty(
      "--y-offset",
      `${(halfHeight - mouseY) / strength}px`,
    );
  }

  update() {
    const currentMarker = appStore.state.currentMarker;
    const tourId = appStore.state.parsedUrl.tourId;

    if (currentMarker && tourId) {
      let src = `${import.meta.env.VITE_CLI_DOMAIN}/${tourId}/latest/images/${currentMarker.id}`;

      if (currentMarker.file_format) {
        src += `.${currentMarker.file_format}`;
      }

      this.render(src);
    }
  }

  /**
   * @param {string} url
   */
  render(url) {
    if (!this.imgEl) return;

    this.imgEl.src = url;
  }
}

customElements.define("ctl-background", Background);
