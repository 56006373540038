class ParameterHandler {
  /**
   * @param {string | Location} url
   */
  constructor(url) {
    /** @type {Object.<string, boolean | string | undefined>} */
    this.parameters = {
      "disable-measurements": false,
      "disable-zoom": false,
      "disable-photos": false,
      "disable-address": false,
      "thumbnail-mode": false,
      "measurement-unit": undefined,
    };

    const searchParams = new URL(`${url}`).searchParams;

    const dm = searchParams.get("dm");
    if (dm == "1") {
      this.parameters["disable-measurements"] = true;
    }

    // Legacy "m" parameter disables measurements when set to 0
    const m = searchParams.get("m");
    if (m && m === "0") {
      this.parameters["disable-measurements"] = true;
    }

    const dz = searchParams.get("dz");
    if (dz == "1") {
      this.parameters["disable-zoom"] = true;
    }

    const dp = searchParams.get("dp");
    if (dp == "1") {
      this.parameters["disable-photos"] = true;
    }

    const da = searchParams.get("da");
    if (da == "1") {
      this.parameters["disable-address"] = true;
    }

    const tn = searchParams.get("tn");
    if (tn == "1") {
      this.parameters["thumbnail-mode"] = true;
    }

    const mu = searchParams.get("mu");
    const isMuValid = mu == "m" || mu == "ft";
    if (mu && isMuValid) {
      this.parameters["measurement-unit"] = mu;
    }
  }

  /**
   * Returns the computed value of a parameter
   * @param {string} paramName
   * @return {boolean | string | undefined}
   */
  getParameterValue(paramName) {
    return this.parameters[paramName];
  }

  /**
   * Returns all valid parameter values as an object
   * @return {Object.<string, boolean | string | undefined>}
   */
  getAllParameters() {
    return this.parameters;
  }
}

/** @type {ParameterHandler | undefined} */
export let parameterHandler = undefined;

/**
 * @param {string | Location} url
 */
export const initialiseParameterHandler = (url) => {
  parameterHandler = new ParameterHandler(url);
};
