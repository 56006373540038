import { appStore } from "./store/index.js";

export default class PlausibleReporter {
  constructor() {
    appStore.events.subscribe("stateChange", () => {
      this.onUpdate();
    });

    /** @type {import("./helpers/getComputedTransforms.js").Image | undefined} */
    this.lastMarker = undefined;

    /** @type {boolean} */
    this.initialised = false;

    /** @type {number} */
    this.imageChanges = 0;
  }

  onUpdate() {
    try {
      if (
        appStore.state.floorplanState !== "ready" ||
        appStore.state.imageState !== "ready" ||
        appStore.state.markerContainerState !== "ready"
      ) {
        return;
      }

      if (this.lastMarker != appStore.state.currentMarker) {
        this.lastMarker = appStore.state.currentMarker;

        if (this.initialised) {
          this.onImageChange();

          return;
        }

        this.initialised = true;
      }
    } catch (err) {
      console.log("#0001", err);
    }
  }

  onImageChange() {
    try {
      this.imageChanges++;

      if (this.imageChanges == 1) {
        // @ts-ignore
        window.plausible("first-image-change");
      }
    } catch (err) {
      console.log("#0002", err);
    }
  }
}
