import StorePrimitive from "./store.js";

export const appStore = new StorePrimitive({
  mutations: {
    setFloorplanState(state, payload) {
      state.floorplanState = payload;
    },
    setImageState(state, payload) {
      state.imageState = payload;
    },
    setMarkerContainerState(state, payload) {
      state.markerContainerState = payload;
    },
    setFloorplanXml(state, payload) {
      state.floorplanXml = payload;
    },
    setParsedUrl(state, payload) {
      state.parsedUrl = payload;
    },
    setConfig(state, payload) {
      state.config = payload;
    },
    setFloor(
      state,
      payload,
      options = {
        skipSetCurrentMarker: false,
        skipSavingFloorImage: false,
      },
    ) {
      if (!options.skipSavingFloorImage) {
        state.lastViewedFloorImage[state.currentFloor] = state.currentMarker;
      }

      state.currentFloor = payload;

      if (!options.skipSetCurrentMarker) {
        if (
          state.lastViewedFloorImage[state.currentFloor]?.id ||
          state.lastViewedFloorImage[state.currentFloor]?.id === 0
        ) {
          appStore.commit(
            "setCurrentMarker",
            state.lastViewedFloorImage[state.currentFloor],
          );
        } else {
          let newImage;

          appStore.state.transforms.order.every((key) => {
            if (
              appStore.state.transforms.images[key].floor == state.currentFloor
            ) {
              newImage = appStore.state.transforms.images[key];
              return false;
            }

            return true;
          });

          if (newImage) {
            appStore.commit("setCurrentMarker", newImage);
          }
        }
      }

      return state;
    },
    setCurrentMarker(state, payload) {
      state.currentMarker = payload;

      return state;
    },
    setTransforms(state, transforms) {
      state.transforms = transforms;

      return state;
    },
    setFloorplanScale(state, transforms) {
      state.transforms = transforms;

      return state;
    },
    setParameters(state, payload) {
      state.parameters = payload;

      return state;
    },
    setDisplayRecenter(state, payload) {
      state.displayRecenter = payload;

      return state;
    },
  },
  state: {
    floorplanXml: undefined,
    floorplanState: undefined,
    imageState: undefined,
    markerContainerState: undefined,
    currentFloor: undefined,
    currentMarker: undefined,
    lastViewedFloorImage: {},
    transforms: [],
    parsedUrl: null,
    floorplanScale: 1,
    parameters: {
      measurements: true,
    },
    displayRecenter: false,
  },
});
