import Component from "../../lib/component.js";
import { appStore } from "../../store/index.js";

export default class InfoCard extends Component {
  constructor() {
    super({
      store: appStore,
      element: document.querySelector("#info-card"),
    });

    this.infoButtonElement = document.querySelector("#info-button");
    this.active = false;
  }

  initialise() {
    if (!this.infoButtonElement) {
      return;
    }

    this.infoButtonElement.addEventListener("click", () => {
      this.element.classList.toggle("active");
      this.active = !this.active;

      try {
        if (this.active) {
          // @ts-ignore
          window.plausible("info-button-click.open");
        } else {
          // @ts-ignore
          window.plausible("info-button-click.close");
        }
      } catch (err) {
        console.log("Plausbile info event error", err);
      }
    });
  }

  update() {}

  render() {}
}
