/**
 * Precisely round numbers
 * @param {number} n number
 * @param {number} digits digits
 * @returns {number}
 */
export const roundTo = (n, digits = 0) => {
  let negative = false;

  if (n < 0) {
    negative = true;
    n = n * -1;
  }

  let multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = +(Math.round(n) / multiplicator).toFixed(digits);
  if (negative) {
    n = +(n * -1).toFixed(digits);
  }
  return n;
};
