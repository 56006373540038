import * as Sentry from "@sentry/browser";

export const initSentry = () => {
  try {
    Sentry.init({
      dsn: "https://86bf3d58979f677542172c586df3da08@o90427.ingest.us.sentry.io/4508012532858880",
      integrations: [],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: import.meta.env.MODE,
      // beforeSend(event) {
      //   console.log(event);

      //   return event;
      // },
    });
  } catch (e) {
    console.log("Sentry error", e);
  }
};
