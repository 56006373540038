import InfoCard from "./components/infoCard/infoCard.js";
import { getComputedTransforms } from "./helpers/getComputedTransforms.js";
import { getConfigJson } from "./helpers/getConfigJson.js";
import { getFloorplan } from "./helpers/getFloorplan.js";
import { getIsIframe } from "./helpers/getIsIframe.js";
import { getParsedUrl } from "./helpers/getParsedUrl.js";
import { getTransformsData } from "./helpers/getTransformsData.js";
import {
  initialiseParameterHandler,
  parameterHandler,
} from "./helpers/parameterHandler.js";
import { initSentry } from "./helpers/sentry.js";
import PlausibleReporter from "./plausibleReporter.js";
import { appStore } from "./store/index.js";
import { writeBody } from "./writeBody.js";
import { writeHead } from "./writeHead.js";

import "./components/background/background";

const handleUrlError = (/** @type {unknown} */ err) => {
  console.log("handleUrlError", err);

  document.body.classList.add("not-found");
};

const initPlausible = () => {
  try {
    let plausible =
      // @ts-ignore
      window.plausible ||
      function () {
        // @ts-ignore
        (window.plausible.q = window.plausible.q || []).push(arguments);
      };

    // @ts-ignore
    function prepareUrl(params) {
      const url = new URL(location.href);
      const queryParams = new URLSearchParams(location.search);
      let customUrl =
        url.protocol + "//" + url.hostname + url.pathname.replace(/\/$/, "");
      for (const paramName of params) {
        const paramValue = queryParams.get(paramName);
        if (paramValue) customUrl = customUrl + "/" + paramValue;
      }
      return customUrl;
    }

    let isCorrectMode =
      import.meta.env.MODE == "production" || import.meta.env.MODE == "staging";

    if (isCorrectMode && window.location.hostname != "localhost") {
      // @ts-ignore
      const version = `${__APP_VERSION__}` || "undefined";

      plausible("pageview", {
        u: prepareUrl(["id"]) + window.location.search,
        props: { version: version, ...parameterHandler?.getAllParameters() },
      });
    }
  } catch (err) {
    console.log("plausible error");
    console.log(err);
  }
};

let floorplanLoaded = false;

const main = async () => {
  initSentry();

  try {
    new PlausibleReporter();
  } catch (err) {
    console.log("#0000", err);
  }

  try {
    initialiseParameterHandler(window.location);

    initPlausible();

    if (parameterHandler?.getParameterValue("disable-photos")) {
      document.body.classList.add("dp-parameter");
    }

    if (parameterHandler?.getParameterValue("thumbnail-mode")) {
      document.body.classList.add("tn-parameter");
    }

    if (
      (parameterHandler?.getParameterValue("disable-photos") &&
        parameterHandler?.getParameterValue("disable-address")) ||
      parameterHandler?.getParameterValue("thumbnail-mode")
    ) {
      document.body.classList.add("hide-header");
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const isTooSmall = window.innerWidth < 300 || window.innerHeight < 600;

    if (
      getIsIframe() &&
      isTooSmall &&
      !parameterHandler?.getParameterValue("thumbnail-mode")
    ) {
      import("./components/coverImage/coverImage.js");

      document.body.innerHTML = `<ctl-cover-image></ctl-cover-image>`;
    } else {
      writeBody();
      writeHead();
    }
  } catch (e) {
    console.log("mobile cover error", e);

    writeBody();
    writeHead();
  }

  try {
    const parsedUrl = getParsedUrl(location);
    appStore.commit("setParsedUrl", parsedUrl);

    const tourId = parsedUrl.tourId;

    Promise.allSettled([
      getTransformsData(tourId),
      getFloorplan(),
      getConfigJson(tourId),
    ]).then((results) => {
      if (results[0].status == "rejected" || results[1].status == "rejected") {
        handleUrlError();
      }

      if (results[2].status == "fulfilled") {
        appStore.commit("setConfig", results[2].value);
      }

      const computedTransforms = getComputedTransforms(
        // @ts-ignore
        results[0].value,
        appStore.state.floorplanXml,
      );

      const firstImage = computedTransforms.images[computedTransforms.order[0]];

      if (!firstImage) {
        handleUrlError();
        return;
      }

      appStore.commit("setTransforms", computedTransforms);
      appStore.commit("setFloor", firstImage.floor, {
        skipSetCurrentMarker: true,
      });
      appStore.commit("setCurrentMarker", firstImage);

      // Launch animation needs to wait for main page load
      floorplanLoaded = true;
      if (customElements.get("ctl-floorplan")) {
        document.body.classList.add("floorplan-ready");
      }

      const infoCardInstance = new InfoCard();

      infoCardInstance.initialise();
    });
  } catch (err) {
    console.log(err);
    handleUrlError(err);
  }

  // try {
  //   const searchParams = new URL(document.location).searchParams;
  // } catch (e) {
  //   console.log("Error in experimental parameter handling", e);
  // }
};

main();

customElements.whenDefined("ctl-floorplan").then(() => {
  // Launch animation needs to wait for main page load
  if (floorplanLoaded) {
    document.body.classList.add("floorplan-ready");
  }
});

const loadStageTwoComponents = async () => {
  import("./components/spaceSizeIndicator/spaceSizeIndicator.js");
  import("./components/address/address.js");
  import("./components/floorIndicator/floorIndicator.js");
  import("./components/floorplan/floorplan.js");
  import("./components/floorSelector/floorSelector.js");
  import("./components/image/image.js");
  import("./components/markerContainer/markerContainer.js");
  import("./components/photoOf/photoOf.js");
  import("./components/spaceTypeIndicator/spaceTypeIndicator.js");
  import("./components/recenterButton/recenterButton.js");
  import("./components/footerLinks/footerLinks.js");

  import("./components/versionIndicator/versionIndicator.js");
};

loadStageTwoComponents();
