import { appStore } from "../store/index.js";

export const getFloorplan = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${import.meta.env.VITE_CLI_DOMAIN}/${appStore.state.parsedUrl.tourId}/latest/${import.meta.env.VITE_SVG_FILE_NAME}`,
    );

    if (!response.ok) {
      return reject();
    }

    const data = await response.text();
    const asd = document.createElement("div");
    asd.innerHTML = data;

    appStore.commit("setFloorplanXml", asd.firstChild);

    return resolve(data);
  });
};
