/**
 * @param {string} tourId
 * @return {Promise<any>}
 */
export const getTransformsData = async (tourId) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_CLI_DOMAIN}/${tourId}/latest/${import.meta.env.VITE_TRANSFORMS_FILE_NAME}?t=${Date.now()}`,
    );

    if (!response.ok) {
      return Promise.reject();
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return Promise.reject();
  }
};
