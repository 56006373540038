/**
 * Get the config.json file for a given tour ID
 * @param {string} tourId - The ID of the tour to get the config for
 * @returns {Promise<Object>} The config data
 */
export const getConfigJson = async (tourId) => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${import.meta.env.VITE_CLI_DOMAIN}/${tourId}/latest/config.json`,
    );

    if (!response.ok) {
      return reject();
    }

    let data = undefined;
    try {
      data = await response.json();
    } catch {
      return reject();
    }

    return resolve(data);
  });
};
