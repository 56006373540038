export const writeBody = () => {
  document.body.innerHTML = `
    <ctl-version-indicator></ctl-version-indicator>
    <svg
      width="0"
      height="0"
      viewBox="0 0 0 0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="filter0_dd_2036_7149" x="14.9746" y="38.5015" width="18.0518" height="18.0518" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2036_7149"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2036_7149"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_2036_7149" result="effect2_dropShadow_2036_7149"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2036_7149" result="shape"/>
        </filter>
        <filter id="filter0_dd_2036_7159" x="2.00586" y="38" width="46" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2036_7159"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2036_7159"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_2036_7159" result="effect2_dropShadow_2036_7159"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2036_7159" result="shape"/>
        </filter>
        <filter id="filter1_dd_2036_7159" x="11.0186" y="35.4873" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2036_7159"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2036_7159"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_2036_7159" result="effect2_dropShadow_2036_7159"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2036_7159" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2036_7159" x1="10.0059" y1="53.5" x2="33.1074" y2="71.7381" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1D4357"/>
        <stop offset="1" stop-color="#008674"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2036_7159" x1="10.0059" y1="53.5" x2="33.1074" y2="71.7381" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1D4357"/>
        <stop offset="1" stop-color="#008674"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2036_7159" x1="25.0049" y1="0.999997" x2="25.0046" y2="51.4766" gradientUnits="userSpaceOnUse">
        <stop stop-color="#50FFAB" stop-opacity="0"/>
        <stop offset="1" stop-color="#50FFAB" stop-opacity="0.72"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2036_7159" x1="25.0039" y1="49" x2="16.9383" y2="10.6985" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0C2F46"/>
        <stop offset="1" stop-color="#006B5C" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2036_7159" x1="0" y1="6" x2="12" y2="12" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1D4357"/>
        <stop offset="1" stop-color="#008674"/>
        </linearGradient>
        <filter id="filter0_dd_2036_7154" x="14.9746" y="38.5015" width="18.0518" height="18.0518" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2036_7154"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2036_7154"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_2036_7154" result="effect2_dropShadow_2036_7154"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2036_7154" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2036_7154" x1="30.1385" y1="55.292" x2="9.09713" y2="43.8982" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1D4357"/>
        <stop offset="1" stop-color="#008674"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2036_7154" x1="23.9999" y1="15.9282" x2="23.9997" y2="47.9282" gradientUnits="userSpaceOnUse">
        <stop stop-color="#50FFAB" stop-opacity="0"/>
        <stop offset="1" stop-color="#50FFAB" stop-opacity="0.72"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2036_7154" x1="24.0001" y1="50.5229" x2="24.1155" y2="17.812" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0C2F46"/>
        <stop offset="0.65" stop-color="#006B5C" stop-opacity="0"/>
        </linearGradient>
      </defs>
    </svg>
    <ctl-background></ctl-background>
    <div id="app">
      <div class="grid-container">
        <div class="header">
          <div class="left">
            <ctl-address></ctl-address>
          </div>
          <div class="right">
            <ctl-space-type-indicator></ctl-space-type-indicator>
            <ctl-space-size-indicator></ctl-space-size-indicator>
          </div>
        </div>
        <div class="floorplan">
          <div id="floorplan-container">
            <ctl-marker-container></ctl-marker-container>
            <ctl-floorplan class="override"></ctl-floorplan>
          </div>
        </div>
        <div class="image"><ctl-image></ctl-image></div>
        <div class="footer" id="footer">
          <div class="mobile-footer">
            <div>
              <ctl-address class="mobile"></ctl-address>
              <span class="footer-disclaimer mobile">
                Results Deemed Highly Reliable But Not Guaranteed
              </span>
            </div>
            <div class="button-container">
              <ctl-floor-selector class="hidden mobile"></ctl-floor-selector>
              <button id="info-button" class="button img-info-button">
                Info
              </button>
            </div>
            <div id="info-card" class="info-card">
              <div id="card-content">
                <ctl-space-type-indicator
                  class="mobile"
                ></ctl-space-type-indicator>
                <ctl-floor-indicator></ctl-floor-indicator>
                <ctl-space-size-indicator></ctl-space-size-indicator>
              </div>
              <div class="info-card-footer">
                <div>
                  <a
                    href="https://www.cubi.casa/privacy-notice/"
                    target="_blank"
                  >
                    Privacy
                  </a>
                  <a href="https://www.cubi.casa/tos/" target="_blank">Terms</a>
                  </div>
              </div>
            </div>
          </div>
          <div class="left">
            <div>
              <ctl-floor-selector class="hidden"></ctl-floor-selector>
              <ctl-recenter-button></ctl-recenter-button>
            </div>
          </div>
          <div class="center">
            <span class="footer-disclaimer">
              Results Deemed Highly Reliable But Not Guaranteed
            </span>
          </div>
          <div class="right">
            <ctl-footer-links></ctl-footer-links>
          </div>
          <div class="backdrop"></div>
        </div>
      </div>
    </div>
    <div class="not-found-container">
      <img src="${import.meta.env.VITE_CLI_DOMAIN}/404.svg" alt="404" />
      <span class="not-found-description">
        This Cubicasa Tour is currently not accessible or it doesn’t exist.
      </span>
    </div>
  `;
};
