import PubSub from "../lib/pubsub.js";

export default class StorePrimitive {
  constructor(params) {
    let self = this;

    // Add some default objects to hold our mutations and state
    self.mutations = {};
    self.state = {};

    // Attach our PubSub module as an `events` element
    self.events = new PubSub();

    // If actions or mutations were passed, use them
    if (params.hasOwnProperty("actions")) {
      self.actions = params.actions;
    }

    if (params.hasOwnProperty("mutations")) {
      self.mutations = params.mutations;
    }

    // Set our state to be a Proxy. We are setting the default state by
    // checking the params and defaulting to an empty object if no default
    // state is passed in
    self.state = new Proxy(params.state || {}, {
      set: function (state, key, value) {
        // Set the value as we would normally
        state[key] = value;
        // Publish the change event for the components that are listening
        self.events.publish("stateChange", self.state);

        return true;
      },
    });
  }

  commit(mutationKey, payload, options) {
    let self = this;

    if (typeof self.mutations[mutationKey] !== "function") {
      console.error(`Mutation "${mutationKey}" doesn't exist`);
      return false;
    }

    // Get a new version of the state by running the mutation and storing the result of it
    const newState = self.mutations[mutationKey](self.state, payload, options);

    // Merge the old and new together to create a new state and set it
    self.state = Object.assign(self.state, newState);

    return true;
  }
}
