export const getParsedUrl = (location) => {
  const params = new URL(location).searchParams;
  let tourId = params.get("id");

  if (!tourId) {
    const lastSlash = String(location)?.split("/")?.at(-1);
    tourId = lastSlash ? lastSlash.split("?")[0] : undefined;
  }

  if (!tourId) {
    throw new Error("tour_id not found");
  }

  return {
    tourId,
  };
};
